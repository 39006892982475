import React, { Component } from 'react'
import { graphql } from 'gatsby'
import {
    Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next, Link } from '@sckit/gatsby-plugin-i18next'

import LocalImage from '../components/local-image'
import Layout from '../components/layout'

import '../css/setting.css'


class WorldSettingPage extends Component {

    render() {

        return (
            <NamespacesConsumer>
            {(t, { i18n }) => {
                return (
                    <Layout siteData={ { title:`${t("TEXT000109")} | ${t("TEXT000006")}`, url:this.props.location.href, img:this.props.data.siteOGImage.publicURL } }>
                        <div id="bodySetting" className="body-page">
                            <section id="slide_1" className="slide"><div className="slide_overlap">
                                <Container className="wrapper">
                                    <div className="content align-self-center">
                                        {t('TEXT000402')}
                                    </div>
                                </Container>
                            </div></section>

                            <section id="slide_2" className="slide"><div className="slide_overlap">
                                <Container className="wrapper">
                                    <div className="content align-self-center">
                                        {t('TEXT000404')}
                                    </div>
                                </Container>
                            </div></section>


                            <section id="slide_3" className="slide"><div className="slide_overlap">
                                <Container className="wrapper">
                                    <div className="content align-self-center">
                                        {t('TEXT000406')}
                                    </div>
                                </Container>
                            </div></section>

                            <section id="slide_4" className="slide"><div className="slide_overlap">
                                <Container className="wrapper">
                                    <div className="content align-self-center">
                                        {t('TEXT000408')}
                                    </div>
                                </Container>
                            </div></section>

                            <section id="slide_5" className="slide"><div className="slide_overlap">
                                <Container className="wrapper">
                                    <div className="content align-self-center">
                                        {t('TEXT000410')}
                                    </div>
                                </Container>
                            </div></section>

                            <section id="slide_6" className="slide ">
                                <Container className="wrapper">
                                    <div className="content text-center know_more">
                                    {/* text-center  justify-content-center d-flex align-items-center */}
                                        <LocalImage name="logo_arw" attr={{className: "col-8 img-fluid logo", alt:"Acid Rain World Logo"}} />

                                        <div className="desp">{t('TEXT000412')}</div>

                                        <Link to="/map/factions" className="d-flex align-items-center">
                                            <LocalImage name="character_icon" attr={{className: "img-fluid", alt:"Factions"}} />
                                            <p>{t('TEXT000026')}</p>
                                        </Link>

                                        <Link to="/creator" className="d-flex align-items-center">
                                            <LocalImage name="creator_icon" attr={{className: "img-fluid", alt:"Creator"}} />
                                            <p>{t('TEXT000004')}</p>
                                        </Link>

                                        <a href="https://story.aither.world/" target="_blank" rel="noopener " className="d-flex align-items-center">
                                            <LocalImage name="mechs_icon" attr={{className: "img-fluid", alt:"Story"}} />
                                            <p>{t('TEXT180007')}</p>
                                        </a>


                                    </div>
                                </Container>
                            </section>

                        </div>

                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(WorldSettingPage)

export const query = graphql`
    query getSettingLang($lng: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }

        siteOGImage: file(name: {eq: "og_setting"}) {
            publicURL
        }
    }
`
